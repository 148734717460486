import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import TagsSection from 'components/admin/ticketing/settings/tagsSection'
import removeQueryParam from 'utils/removeQueryParam'
import useQueryParams from 'components/common/hooks/useQueryParams'
import IntegrationsSection from 'components/admin/ticketing/settings/integrationsSection'
import DepartmentsSection from 'components/admin/ticketing/settings/departmentsSection'
import ClearyAiSection from 'components/admin/ticketing/settings/clearyAiSection'

const TicketSettings = () => {
  const { settingsSection } = useQueryParams()
  const history = useHistory()

  useEffect(() => () => {
    removeQueryParam(history, 'settingsSection')
  }, [])

  return (
    <div>
      {settingsSection === 'tags' && <TagsSection />}
      {settingsSection === 'departments' && <DepartmentsSection />}
      {settingsSection === 'integrations' && <IntegrationsSection />}
      {settingsSection === 'cleary_ai' && <ClearyAiSection />}
    </div>
  )
}

export default TicketSettings
