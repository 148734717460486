import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import TicketingContext from 'components/ticketing/ticketingContext'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'
import { TicketType } from 'types/ticketing/ticket'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

type Props = {
  ticket: TicketType
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const AssigneeSelect = ({ ticket }: Props) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { fetchCounts } = useContext(TicketingContext)
  const [assignTicket, { isLoading: isAssigning }] = useApi(API.admin.ticketing.tickets.assign, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_successfully_saved'),
    onError: (error) => {
      dispatch(showToastMessage({ message: error?.message || I18NCommon('generic_error'), type: 'error' }))
    },
    onSuccess: fetchCounts,
  })
  const isClosed = ticket.status === 'closed'

  if (isAssigning) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='AssigneeSelect d-flex flex-column'>
      <EmployeeSearch
        selectedEmployee={ticket.assignee}
        onChange={employee => assignTicket(ticket.id, employee?.id)}
        placeholder={I18NCommon('unassigned')}
        isDisabled={isClosed}
      />
      {!isClosed && currentUser.id !== ticket.assignee?.id && (
        <div className='text-smallest link-color pointer mt-2 align-self-end' onClick={() => assignTicket(ticket.id, currentUser.id)}>
          {I18NCommon('assign_to_me')}
        </div>
      )}
    </div>
  )
}

export default AssigneeSelect
