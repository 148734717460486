import React, { useState } from 'react'

import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import useApi from 'components/common/hooks/useApi'
import ReactSelect from 'components/common/react_select'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import InfoTooltip from 'components/common/infoTooltip'
import { Button } from 'components/common/buttons'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'

const I18N = i18nPath('views.admin.ticketing.settings.integrations')

interface Department {
  id: number;
  name: string;
}

const departmentOptions = (departments: Department[]) => departments.map(dept => ({ value: dept.id, label: dept.name }))

const IntegrationsSection = () => {
  const { data, isLoaded } = useFetch(API.admin.ticketing.departments.fetchAll)
  const [updateConfig, { isLoading: isSaving }] = useApi(
    API.admin.companyConfigurations.update,
    { toastSuccessMessage: I18NCommon('changes_saved') }
  )

  const {
    settings: {
      ticketing: {
        integration: { suggestedMessageDepartments, appName, signature },
      },
    },
  } = useCurrentCompany()

  const [currentDepartments, setCurrentDepartments] = useState(suggestedMessageDepartments)
  const [currentAppName, setCurrentAppName] = useState(appName)
  const [currentAgentSignature, setCurrentAgentSignature] = useState(signature)

  const onSubmit = () => {
    updateConfig({
      id: 'ticketing',
      value: {
        integration: {
          appName: currentAppName,
          suggestedMessageDepartments: currentDepartments,
          signature: currentAgentSignature,
        },
      },
    })
  }

  return (
    <div>
      <header className='AdminHeader bordered-bottom'>
        <h3>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <div className='mt-4 w-100 w-lg-75'>
          {isLoaded && (
            <>
              <div className='form-group'>
                <label>
                  {I18N('suggested_message_departments.label')}
                  <InfoTooltip text={I18N('suggested_message_departments.helper')} />
                </label>
                <ReactSelect
                  name='departments'
                  isMulti
                  value={departmentOptions(data.filter(dept => currentDepartments.includes(dept.id)))}
                  options={departmentOptions(data)}
                  onChange={(selectedOptions) => {
                    setCurrentDepartments(selectedOptions.map(option => option.value))
                  }}
                />
              </div>

              <div className='form-group'>
                <label>
                  {I18N('app_name.label')}
                  <InfoTooltip text={I18N('app_name.helper')} />
                </label>
                <input
                  type='text'
                  value={currentAppName}
                  onChange={(e) => {
                    setCurrentAppName(e.target.value)
                  }}
                />
              </div>

              <label className='w-100'>
                Agent Signature
              </label>
              <div className='form-group d-flex gap-4'>
                <div className='w-50'>
                  <textarea
                    value={currentAgentSignature}
                    onChange={(e) => {
                      setCurrentAgentSignature(e.target.value)
                    }}
                  />
                </div>
                <div className='w-50'>
                  <TipTapRichTextView html={currentAgentSignature} />
                </div>
              </div>

              <Button onClick={onSubmit} showLoadingSpinner={isSaving}>
                {I18NCommon('save')}
              </Button>
            </>
          )}
        </div>
      </main>
    </div>
  )
}

export default IntegrationsSection
