import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { Popover } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import CdnSvg from 'components/common/cdnSvg'
import useClickOutside from 'components/common/hooks/useClickOutside'
import { addQueryParam } from 'utils/removeQueryParam'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.ticketing.settings')

const SettingItem = ({ iconSrc, label, onClick }: { iconSrc: string, label: string, onClick: () => void }) => (
  <div className='MenuItem d-flex align-items-center gap-2' onClick={onClick}>
    <CdnSvg src={iconSrc} />
    <span className='text-small'>{label}</span>
  </div>
)

interface SettingsLinkProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
}

const SettingsLink = ({
  selectedTab,
  setSelectedTab,
}: SettingsLinkProps) => {
  const history = useHistory()
  const dropdownRef = useRef<any>(null)
  const [showDropdown, setShowDropdown] = useState(false)

  useClickOutside({
    ref: dropdownRef,
    onClickOutside: () => setShowDropdown(false),
    enableClickOutside: showDropdown,
  })

  const handleClick = (section: string) => {
    setSelectedTab('settings')
    addQueryParam(history, 'settingsSection', section)
    setShowDropdown(false)
  }

  return (
    <div className='SettingsLink'>
      <div
        className={classNames('SidebarItem', {
          active: showDropdown || selectedTab === 'settings',
        })}
        id='helpdesk-settings'
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <CdnSvg src='/images/gearIcon.svg' />
        <span>{I18N('helpdesk_settings')}</span>
      </div>

      <Popover
        className='HelpdeskSettingsMenu'
        target='helpdesk-settings'
        placement='top-end'
        isOpen={showDropdown}
        toggle={() => setShowDropdown(!showDropdown)}
        hideArrow
      >
        <div ref={dropdownRef}>
          <SettingItem iconSrc='/images/tagIcon.svg' label={I18N('tags.manage')} onClick={() => handleClick('tags')} />
          <SettingItem iconSrc='/images/personsIcon.svg' label={I18N('departments.title')} onClick={() => handleClick('departments')} />
          <SettingItem iconSrc='/images/globeIcon.svg' label={I18N('integrations.manage')} onClick={() => handleClick('integrations')} />
          <SettingItem iconSrc='/images/aiIcon.svg' label='Cleary AI' onClick={() => handleClick('cleary_ai')} />
        </div>
      </Popover>
    </div>
  )
}

export default SettingsLink
