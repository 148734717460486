import React from 'react'
import { useSelector } from 'react-redux'

import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import EmojiPicker from 'components/common/emojiPicker'
import useApi from 'components/common/hooks/useApi'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import { RootSidebar } from 'components/common/sidebar'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.admin.ticketing.settings.departments')

const newWorkingCopy = {
  emojiCode: '',
  name: '',
  email: '',
}

interface DepartmentEditorSidebarProps {
  departmentId: string
  onClose: () => void
  isOpen: boolean
  setDepartmentIds: any
}

const DepartmentEditorSidebar = ({
  departmentId,
  onClose,
  isOpen,
  setDepartmentIds,
}: DepartmentEditorSidebarProps) => {
  const isNew = departmentId === 'new'
  const department = useSelector(entitySlice.selectors.getEntity('ticketingDepartment', departmentId))
  const { isLoading } = useFetch(() => API.admin.ticketing.departments.fetch(departmentId), [departmentId], {
    load: !department && !isNew,
    updateEntitySlice: true,
    onSuccess: data => setWorkingCopy(data),
  })

  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState(department || newWorkingCopy)

  const [updateDepartment, { isLoading: isUpdatingDepartment }] = useApi(
    API.admin.ticketing.departments.update,
    {
      updateEntitySlice: true,
      onSuccess: onClose,
      toastSuccessMessage: I18NCommon('updated_successfully'),
    }
  )

  const [createDepartment, { isLoading: isCreatingDepartment }] = useApi(
    API.admin.ticketing.departments.create,
    {
      updateEntitySlice: true,
      onSuccess: (data) => {
        setDepartmentIds(prev => [data.id, ...prev])
        onClose()
      },
      toastSuccessMessage: I18NCommon('created_successfully'),
    }
  )

  const onSave = () => {
    isNew ? createDepartment(workingCopy) : updateDepartment(workingCopy)
  }

  return (
    <RootSidebar
      title={null}
      onClose={onClose}
      isOpen={isOpen}
      className='DepartmentEditorSidebar'
      ignoredSelectorsWhenClickOutside={['.popover']}
      useBackdrop
    >
      {isLoading ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <h5 className='mb-4'>{I18N(isNew ? 'new_department' : 'edit_department')}</h5>
          <div className='d-flex flex-column gap-5'>
            <div>
              <label htmlFor='name'>{I18N('icon_and_name')}</label>
              <div className='d-flex align-items-center'>
                <EmojiPicker
                  onEmojiSelect={emoji => updateWorkingCopy({ emojiCode: emoji.native })}
                  trigger={<EmojiOrPlaceholder selectedEmoji={workingCopy.emojiCode} />}
                  triggerClassName='mr-2 h-100'
                  disableCustomReactions
                  className='mb-1'
                />
                <input
                  id='name'
                  className='w-100'
                  value={workingCopy.name}
                  onChange={e => updateWorkingCopy({ name: e.target.value })}
                  placeholder={I18N('name_placeholder')}
                />
              </div>
            </div>
            <div>
              <label htmlFor='email'>{I18N('email')}</label>
              <input
                id='email'
                className='w-100'
                value={workingCopy.email}
                onChange={e => updateWorkingCopy({ email: e.target.value })}
                placeholder={I18N('email')}
              />
            </div>

            <div className='d-flex align-items-center justify-content-end gap-2'>
              <CancelButton onClick={onClose} />
              <ButtonNarrow
                onClick={onSave}
                disabled={isUpdatingDepartment || isCreatingDepartment}
                showLoadingSpinner={isUpdatingDepartment || isCreatingDepartment}
              >
                {I18NCommon('save')}
              </ButtonNarrow>
            </div>
          </div>
        </>
      )}
    </RootSidebar>
  )
}

export default DepartmentEditorSidebar
