import React, { useState } from 'react'

import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import useApi from 'components/common/hooks/useApi'
import ReactSelect from 'components/common/react_select'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import InfoTooltip from 'components/common/infoTooltip'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.admin.ticketing.settings.cleary_ai')

interface Department {
  id: number;
  name: string;
}

const departmentOptions = (departments: Department[]) => departments.map(dept => ({ value: dept.id, label: dept.name }))

const ClearyAiSection = () => {
  const { data, isLoaded } = useFetch(API.admin.ticketing.departments.fetchAll)
  const [updateConfig, { isLoading: isSaving }] = useApi(
    API.admin.companyConfigurations.update,
    { toastSuccessMessage: I18NCommon('changes_saved') }
  )

  const {
    settings: {
      ticketing: {
        clearyAi: { automatedFaqDepartmentsEnabled },
      },
    },
  } = useCurrentCompany()

  const [currentDepartments, setCurrentDepartments] = useState(automatedFaqDepartmentsEnabled)

  const onSubmit = () => {
    updateConfig({
      id: 'ticketing',
      value: {
        clearyAi: {
          automatedFaqDepartmentsEnabled: currentDepartments,
        },
      },
    })
  }

  return (
    <div>
      <header className='AdminHeader bordered-bottom'>
        <h3>Cleary AI</h3>
      </header>

      <main className='AdminContent'>
        <div className='mt-4 w-100 w-lg-75'>
          {isLoaded && (
            <>
              <div className='form-group'>
                <label>
                  {I18N('automated_faq_departments_enabled.label')}
                  <InfoTooltip text={I18N('automated_faq_departments_enabled.helper')} />
                </label>
                <ReactSelect
                  name='departments'
                  isMulti
                  value={departmentOptions(data.filter(dept => currentDepartments.includes(dept.id)))}
                  options={departmentOptions(data)}
                  onChange={(selectedOptions) => {
                    setCurrentDepartments(selectedOptions.map(option => option.value))
                  }}
                />
              </div>

              <Button onClick={onSubmit} showLoadingSpinner={isSaving}>
                {I18NCommon('save')}
              </Button>
            </>
          )}
        </div>
      </main>
    </div>
  )
}

export default ClearyAiSection
