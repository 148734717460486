import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import DepartmentEditorSidebar from 'components/admin/ticketing/settings/departmentEditorSidebar'
import ButtonWithIcon from 'components/common/buttons/buttonWithIcon'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import TableLoadingContainer from 'components/common/loadingContainer/tableLoadingContainer'
import DeleteModal from 'components/common/modals/deleteModal'
import SmartTable from 'components/common/tables/smartTable'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.ticketing.settings.departments')

const DepartmentSettingsDropdown = ({
  departmentId,
  setSelectedDepartmentId,
  setDepartmentIds,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [deleteDepartment, { isLoading: isDeleting }] = useApi(
    () => API.admin.ticketing.departments.destroy({ id: departmentId }),
    {
      toastSuccessMessage: I18NCommon('deleted_successfully'),
      onSuccess: () => {
        setIsDeleteModalOpen(false)
        setDepartmentIds(prev => prev.filter(id => id !== departmentId))
      },
    }
  )

  return (
    <>
      <DropdownMenuContainer
        menuType='kebab'
        className='DepartmentSettingsDropdown default-dropdown'
      >
        <CardDropdownMenuItem
          className='Card-dropdownMenuItem'
          onClick={() => setSelectedDepartmentId(departmentId)}
          primaryText={I18NCommon('edit')}
          icon={<CdnSvg src='/images/pencilIcon.svg' className='mr-2' />}
        />
        <CardDropdownMenuItem
          className='Card-dropdownMenuItem--danger'
          onClick={() => setIsDeleteModalOpen(true)}
          primaryText={I18NCommon('delete')}
          icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
        />
      </DropdownMenuContainer>

      {isDeleteModalOpen && (
        <DeleteModal
          deleteText={I18N('delete_modal.delete_text')}
          deleteSecondaryText={I18N('delete_modal.delete_secondary_text')}
          deleteConfirm={deleteDepartment}
          showDeleteModal
          closeDeleteModal={() => setIsDeleteModalOpen(false)}
          isDeleting={isDeleting}
        />
      )}
    </>
  )
}

const DepartmentsSection = () => {
  const [departmentIds, setDepartmentIds] = useState([])
  const { isLoading } = useFetch(API.admin.ticketing.departments.fetchAll, [], {
    updateEntitySlice: true,
    onSuccess: departments => setDepartmentIds(departments.map(department => department.id)),
  })

  const departments = useSelector(entitySlice.selectors.getEntities('ticketingDepartment', departmentIds))

  const [selectedDepartmentId, setSelectedDepartmentId] = useQueryParamState<string | undefined>({
    param: 'departmentId',
    initialValue: undefined,
  })

  const columns = [
    {
      header: I18N('name'),
      accessor: department => (
        <div className='d-flex align-items-center'>
          <EmojiOrPlaceholder selectedEmoji={department.emojiCode} className='mr-2' />
          <span className='truncate-text-at-1-lines'>{department.name}</span>
        </div>
      ),
    },
    {
      header: I18N('email'),
      accessor: department => department.email,
    },
    {
      header: I18N('created_at'),
      accessor: department => i18nMoment(department.createdAt).format('lll'),
    },
    {
      style: { width: '10%' },
      accessor: department => (
        <DepartmentSettingsDropdown
          departmentId={department.id}
          setSelectedDepartmentId={setSelectedDepartmentId}
          setDepartmentIds={setDepartmentIds}
        />
      ),
    },
  ]

  return (
    <div>
      <header className='AdminHeader bordered-bottom d-flex justify-content-between'>
        <h3>{I18N('title')}</h3>
        <ButtonWithIcon
          onClick={() => setSelectedDepartmentId('new')}
        >
          {I18N('new_department')}
        </ButtonWithIcon>
      </header>

      <main className='AdminContent'>
        <TableLoadingContainer isLoading={isLoading} useCirclesLoadingIndicator>
          <SmartTable
            data={departments}
            columns={columns}
            showPagination={false}
            className='white-bg-table'
          />
        </TableLoadingContainer>
      </main>

      {selectedDepartmentId && (
        <DepartmentEditorSidebar
          onClose={() => {
            setSelectedDepartmentId('')
          }}
          isOpen={!!selectedDepartmentId}
          departmentId={selectedDepartmentId}
          setDepartmentIds={setDepartmentIds}
        />
      )}
    </div>
  )
}

export default DepartmentsSection
